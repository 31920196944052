import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import { GET_HOSPITAL, GET_HOSPITALS, STORE_HOSPITAL, UPDATE_HOSPITAL } from "../store/modules/hospital.module";
export  default {
  methods: {
    storeHospital(formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(STORE_HOSPITAL, formData), actions)
    },
    getHospital(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_HOSPITAL, id), actions)
    },
    updateHospital(id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(UPDATE_HOSPITAL, { id, formData }), actions)
    },
    getHospitals(page = 1, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_HOSPITALS, page), actions)
    },
  }
}
